(function($, ScrollTrigger) {
  // use a script tag or an external JS file
  document.addEventListener("DOMContentLoaded", (event) => {
    setTimeout(function() {
      $('.loading').stop().fadeOut(400);
      $('.index-banner-txt div').addClass('anime anime-run');
    }, 3000);

    gsap.registerPlugin(ScrollTrigger);

    let panels = gsap.utils.toArray(".panel");
    // we'll create a ScrollTrigger for each panel just to track when each panel's top hits the top of the viewport (we only need this for snapping)
    let tops = panels.map(panel => ScrollTrigger.create({trigger: panel, start: "top top"}));
    function panelUpdate(current) {
      $('.index-banner-scroll .pager .current').text(current);
        var logoColor = $('.panel[data-index='+current+']').data('logo-color');
        $('.site-title .logo').removeClass('dark white');
        $('.site-title .logo').addClass(logoColor);
        if (panels.length==current) {
          $('.index-banner').addClass('last');
        } else {
          $('.index-banner').removeClass('last');
        }
    }
    
    panels.forEach((panel, i) => {
      if (i<panels.length-1) {
        ScrollTrigger.create({
          trigger: panel,
          start: () => panel.offsetHeight < window.innerHeight ? "top top" : "bottom bottom", // if it's shorter than the viewport, we prefer to pin it at the top
          pin: true, 
          pinSpacing: i<panels.length-1?false:true,
          snap: i<panels.length-1?1:false,
          // onEnter: (self) => {
          //   var index = $(self.trigger).data('index');
          //   var current = index;
          //   panelUpdate(current);
          // },
          // onEnterBack: (self) => {
          //   var index = $(self.trigger).data('index');
          //   // console.log('index', index);
          //   var current = index;
          //   panelUpdate(current);
          // },
          // onLeave: (self) => {
          //   var index = $(self.trigger).data('index');
          //   var current = index+1>5?'5':index+1;
          //   panelUpdate(current);
          // },
          onUpdate: (self) => {
            var index = $(self.trigger).data('index');
            var current = index;
            if (self.direction > 0 && self.progress.toFixed(1) > 0.1) {
              var current = index+1>5?'5':index+1;
            }
            panelUpdate(current);
            
            if (panels.length==current && self.progress.toFixed(1) == 1) {
              $('.index-banner').addClass('end');
            } else {
              $('.index-banner').removeClass('end');
            }
            console.log('onUpdate', self.direction, self.progress.toFixed(1), current);
          },
        });
      }
    });
  });
  $('.index-case-slider').slick({
    arrows: false,
    dots: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
    ]
  });
  $('.case-pic-slider').slick({
    fade: true,
    autoplay: true,
    autoplaySpeed: 4000,
    adaptiveHeight: true,
    pauseOnHover: true,
    arrows: false,
    dots: true,
    swipe: false,
  });
  $('.index-news-slider').slick({
    arrows: true,
    dots: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    appendArrows: $('.index-news-arrows'),
    prevArrow: '<button class="slick-prev bt" aria-label="Previous" type="button"><i class="ic ic-arrow-left circle"></i></button>',
    nextArrow: '<button class="slick-next bt" aria-label="Next" type="button"><i class="ic ic-arrow-right circle"></i></button>',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
    ]
  });
  $('.index-portfolio-slider').slick({
    arrows: true,
    dots: false,
    prevArrow: '<button class="slick-prev bt" aria-label="Previous" type="button"><i class="ic ic-arrow-left circle"></i></button>',
    nextArrow: '<button class="slick-next bt" aria-label="Next" type="button"><i class="ic ic-arrow-right circle"></i></button>',
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '22.5%',
          arrows: false,
        }
      },
    ]
  });
})($, ScrollTrigger);